


































































































import { Component, Vue } from "vue-property-decorator";
import Header from '@/components/header.vue'; // @ is an alias to /src
import SidebarFinance from '@/components/sidebar-finance.vue'; // @ is an alias to /src
import BuiltinService from '@/services/buitin-service';
import accountService from '@/services/account-service';
@Component({
  components: {
    Header,
    SidebarFinance,
  },
})
export default class OpeningBalance extends Vue {
  
  public items = [
    {
      identity : 0,
      account : '',
      transaction : '', 
      account_title : '',
      account_code : '',
      cost_center : '',
      description : '',
      debit : 0.00,
      credit : 0.00,
      amount : '',
      reference_no : '',
      request_no : '',
      comments : '',
    }
    ];
  selected_project: any ={};
  option_accounts:any = [];  
  branch_options = [];
  options_project:any = [];
  trans :any = {};
  auth:any = {};
  sum_d: number = 0
  sum_c :number = 0;
  total = 0.00;
  transaction = ''
  sum_transaction = 0;
  index = 0;
    

  getaccounts(){
    BuiltinService.getauth()
      .then((response) => {
        this.auth = response.data[0];
      })
      .catch((e) => {
        console.log(e);
      });
      BuiltinService.getproject()
      .then((response) => {
        this.options_project = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
      // accountService.getAccounts()
      // .then((response) => {  
      //   this.option_accounts = response.data.result;      
      //   })
      // .catch((e) => {
      //   console.log(e);
      // });
      
  }
  addRow(){      
    
       this.items.push({
          identity: this.items.length + 1,
          account : '',
          transaction : '', 
          account_code : '', 
          account_title : '',
          cost_center : '',
          description : '',
          debit : 0.00,
          credit :0.00,
          amount : '',
          reference_no : '',
          request_no : '',
          comments : '',
          
    })
    
  }
  save(){
    let sum_c = 0;
    let sum_d = 0;
    for (var i = 0; i < this.items.length; i++) {sum_c +=  (+this.items[i]['credit'])}
    for (var i = 0; i < this.items.length; i++) {sum_d +=  (+this.items[i]['debit'])}
    
    // this.sum_transaction = sum_c - sum_d
    if(sum_c == sum_d){
      this.trans['items'] = this.items;    
    this.trans['branch'] = this.selected_project.title;    
    this.trans['code'] = '0'+ (this.items.length + 1).toString() 
    accountService.getTransaction(this.trans)
      .then((response) => {  
        this.trans = {};
        this.items = [];
        this.selected_project = {};
        this.index = 0;
        this.addRow();
        this.transaction = 'Transaction Generated ' + response.data.code;
        alert(this.transaction)
        })
      .catch((e) => {
        console.log(e);
      });

    }else{
      alert('Sum must be equal')
    }    
  }

  get_parent_account(data: any){      

    this.items[this.index].account_title = data.title
    this.items[this.index].account_code = data.code
    this.items[this.index].account = data.id
    this.option_accounts = [];    
  }

  search_parent_account(title: any, index:any){
    this.index = index
    if(title.length > 4){
      accountService.getByTitle(title)
      .then((response) => {  
        this.option_accounts = response.data.result;      
        })
      .catch((e) => {
        console.log(e);
      });
    }
    if(title.length == 0){
        this.option_accounts = [];
    }    
  }

  mounted() {
    
      let current = new Date();
      let start_date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
      this.trans['date'] = start_date;
    this.getaccounts();
  }
}
